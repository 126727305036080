(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ForgotPasswordController', ForgotPasswordController);

  ForgotPasswordController.$inject = ['$state', 'passwordResetService'];

  function ForgotPasswordController($state, passwordResetService) {
    var vm = this;

    vm.submit = submit;

    function submit(isValid, data){
      if (! isValid) return;

      passwordResetService.requestReset(data)
        .then(function(response) {
          $state.go('reset-link-sent');
        });

    }

  }

})();