(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('QualificationListController', QualificationListController);

  QualificationListController.$inject = ['$scope', 'qualificationDataService', 'toastr'];

  function QualificationListController($scope, qualificationDataService, toastr) {
    var vm = this;

    vm.qualifications = [];

    vm.destroy = destroy;
    vm.update = update;

    activate();

    $scope.$on('created', function(e) {
      activate();
    });

    function activate() {
      qualificationDataService.fetch()
        .then(function(data) {
          vm.qualifications = data.data;
          return vm.qualifications;
        });
    }

    function destroy(qualification_id) {
      qualificationDataService.destroy(qualification_id)
        .then(function() {
          toastr.success('Qualification deleted.');
          activate();
        }, function(data) {
          toastr.error(data.data.message, 'Cannot Delete');
        });
    }

    function update(data) {
      qualificationDataService.update(data)
        .then(function() {
          toastr.success('Qualification updated.');
          activate();
        });
    }

  }

})();