(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    
  }

})();