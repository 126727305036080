(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('uniqueApplicationValidator', uniqueApplicationValidator);

  uniqueApplicationValidator.$inject = ['$http', '$q'];

  function uniqueApplicationValidator($http, $q) {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        ngModel.$asyncValidators.uniqueApplication = function(email) {
          var dfd = $q.defer();

          $http.get('/api/applications/unique-email?email=' + email)
            .then(function(data) {
              if ( ! data.data.unique) {
                dfd.reject();
              } else {
                dfd.resolve();
              }
            });

          return dfd.promise;
        }
      }
    }
  }
})();