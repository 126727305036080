(function () {
  'use strict';

  angular
    .module('GeneManagement')
    .factory('GeneType', GeneTypeModel);

  GeneTypeModel.$inject = ['$resource'];

  function GeneTypeModel($resource) {

    return $resource('/api/gene-types/:id', {id: '@id'}, {
      'update': { method: 'PUT', params: {id: '@id'} }
    });

  }

})();