(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('unauthorised', unauthorised);

  unauthorised.$inject = ['$q', '$injector'];

  function unauthorised($q, $injector) {
    return {
      responseError: responseError
    };

    function responseError(rejection) {
      if (rejection.status === 403) {
        var $state = $injector.get('$state');

        $state.go('unauthorised');
      }

      return $q.reject(rejection);
    }

  }

})();