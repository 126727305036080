(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('scale', scale);

  function scale() {
    return function(value) {
      var scale = [];

      for (var n = 1; n <= value; n += 1) {
        scale.push(n);
      }

      return scale;
    };
  }

})();