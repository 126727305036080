(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('UserShowController', UserShowController);

    UserShowController.$inject = ['$stateParams', 'userDataService'];

    function UserShowController($stateParams, userDataService) {
      var vm = this;

      vm.user = {};

      activate();

      function activate() {
        userDataService.show($stateParams.id)
          .then(function(data) {
            vm.user = data.data;
            return vm.user;
          });
      }

    }
    
})();