(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('participateDataService', participateDataService);

  participateDataService.$inject = ['$http'];

  function participateDataService($http) {

    return {
      fetch: fetch,
      getJson: getJson,
      store: store
    };

    function fetch(questionnaire_id) {
      return $http.get('/api/participate/' + questionnaire_id);
    }

    function getJson(file) {
      return $http.get('/api/participate/file/' + file);
    }

    function store(questionnaire_id, responses) {
      return $http.post('/api/participate/' + questionnaire_id, responses);
    }
  }

})();