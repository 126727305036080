(function() {
  'use strict';

  angular
    .module('SubScale')
    .controller('SubScaleEditController', SubScaleEditController);

  SubScaleEditController.$inject = ['$state', '$stateParams', 'subScaleDataService', 'toastr'];

  function SubScaleEditController($state, $stateParams, subScaleDataService, toastr) {
    var ctrl = this;

    ctrl.form = {};
    ctrl.questions = [];
    ctrl.formError = false;

    ctrl.checkIncluded = checkIncluded;
    ctrl.removeUnchecked = removeUnchecked;
    ctrl.update = update;

    activate();

    function activate() {
      return subScaleDataService.find($stateParams.id)
        .then(function(response) {
          ctrl.form = response.data;
          return subScaleDataService.questions(response.data.questionnaire_id);
        })
        .then(function(response) {
          ctrl.questions = response.data;
        });
    }

    function checkIncluded(index) {
      if (ctrl.form.questions[index].reverse === true) {
        ctrl.form.questions[index].include = true;
      } else {
        delete ctrl.form.questions[index].reverse;
      }
    }

    function removeUnchecked() {
      angular.forEach(ctrl.form.questions, function(item, idx) {
        if (item.include === false) {
          delete ctrl.form.questions[idx];
        }
      });
    }

    function update(isValid, formData) {
      if (! isValid || Object.keys(formData.questions).length === 0) {
        ctrl.formError = true;
        return;
      }

      subScaleDataService.update($stateParams.id, formData)
        .then(function(response) {
          toastr.success(response.data.message);
          $state.go('sub-scales.index');
        });
    }

  }

})();