(function() {
  'use strict';

  angular
    .module('Wasi')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    
    $stateProvider
      .state('wasi', {
        abstract: true,
        url: '/wasi',
        template: '<ui-view>'
      })
      .state('wasi.upload', {
        url: '/upload/:id',
        templateUrl: '../views/wasi/upload.html',
        controller: 'WasiUploadController',
        controllerAs: 'UploadCtrl',
        authenticate: true,
        permission: 'wasi-upload'
      });
      
  }

})();