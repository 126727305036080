(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {

    $stateProvider
      .state('recruitment', {
        url: '/recruitment',
        abstract: true,
        template: '<ui-view />'
      })
      .state('recruitment.index', {
        url: '',
        templateUrl: '../views/recruitment/index.html',
        controller: 'RecruitmentIndexController',
        controllerAs: 'recruitment',
        authenticate: true,
        permission: 'recruitment-view'
      });

  }

})();