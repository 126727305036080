(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('qualificationDataService', qualificationDataService);

  qualificationDataService.$inject = ['$http'];

  function qualificationDataService($http) {

    return {
      destroy: destroy,
      fetch: fetch,
      save: save,
      update: update
    };


    function destroy(qualification_id) {
      return $http.delete('/api/qualification/' + qualification_id);
    }

    function fetch() {
      return $http.get('/api/qualification');
    }

    function save(data) {
      return $http.post('/api/qualification', data);
    }

    function update(qualification) {
      return $http.put('/api/qualification/' + qualification.id, qualification);
    }

  }

})();