(function () {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider', '$urlRouterProvider'];

  function config($stateProvider, $urlRouterProvider) {

    $stateProvider
      .state('questionnaires', {
        abstract: true,
        url: '/questionnaires',
        template: '<ui-view />'
      })
      .state('questionnaires.index', {
        url: '',
        templateUrl: '../views/questionnaires/index.html',
        controller: 'QuestionnaireIndexController',
        controllerAs: 'qnc',
        authenticate: true,
        permission: 'questionnaires-view'
      })
      .state('questionnaires.create', {
        url: '/create',
        templateUrl: '../views/questionnaires/create.html',
        controller: 'QuestionnaireCreateController',
        controllerAs: 'qnc',
        authenticate: true,
        permission: 'questionnaires-create'
      })
      .state('questionnaires.upload', {
        url: '/upload/{id}',
        templateUrl: '../views/questionnaires/upload.html',
        controller: 'QuestionnaireUploadController',
        controllerAs: 'UploadCtrl',
        authenticate: true,
        permission: 'questionnaires-upload'
      });
  }

})();