(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('QualificationCreateController', QualificationCreateController);

  QualificationCreateController.$inject = ['$scope', 'qualificationDataService', 'toastr'];

  function QualificationCreateController($scope, qualificationDataService, toastr) {
    var vm = this;

    vm.data = {};

    vm.save = save;

    function save(isValid, data) {
      if ( ! isValid) return;

      qualificationDataService.save(data)
        .then(function() {
          toastr.success('New qualification created.');

          vm.data = {};
          $scope.qualForm.$setPristine();
          $scope.qualForm.$setUntouched();

          $scope.$emit('created');
        });
    }
  }

})();