(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('RecruitmentCreateController', RecruitmentCreateController);

  RecruitmentCreateController.$inject = ['$scope', 'recruitmentDataService', 'toastr'];

  function RecruitmentCreateController($scope, recruitmentDataService, toastr) {
    var vm = this;
    vm.data = {};

    vm.submit = submit;

    function submit(isValid, data) {
      if ( ! isValid) return;

      recruitmentDataService.store(data)
        .then(function() {
          toastr.success('Recruitment Source has been created.');
          vm.data = {};
          $scope.rsForm.$setPristine();
          $scope.rsForm.$setUntouched();

          $scope.$emit('recruitment-source-created');
        });
    }

  }

})();
