(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    
    $stateProvider
      .state('users', {
        abstract: true,
        url: '/users',
        template: '<ui-view />'
      })
      .state('users.index', {
        url: '',
        templateUrl: '../views/users/index.html',
        controller: 'UserIndexController',
        controllerAs: 'User',
        authenticate: true,
        permission: 'users-search'
      })
      .state('users.show', {
        url: '/:id',
        templateUrl: '../views/users/show.html',
        controller: 'UserShowController',
        controllerAs: 'UserShow',
        authenticate: true,
        permission: 'users-view'
      })
      .state('users.roles', {
        url: '/assign-roles/:id',
        templateUrl: '../views/users/assign-roles.html',
        controller: 'UserRolesController',
        controllerAs: 'UserCtrl',
        authenticate: true,
        permission: 'users-roles'
      });
  }

})();