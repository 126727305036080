(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ParticipantCreateController', ParticipantCreateController);

  ParticipantCreateController.$inject = ['$state', 'participantDataService', 'recruitmentDataService', 'toastr'];

  function ParticipantCreateController($state, participantDataService, recruitmentDataService, toastr) {
    var vm = this;

    vm.recruitmentSources = [];

    vm.submit = submit;

    activate();

    function activate() {
      recruitmentDataService.getSources()
        .then(function(data) {
          vm.recruitmentSources = data.data;
          return vm.recruitmentSources;
        });
    }

    function submit(isValid, data) {
      if ( ! isValid) return;

      participantDataService.store(data)
        .then(function(data) {
          toastr.success('Participant record created.');
          $state.go('participants.show', {'id': data.data.id});
        });
    }

  }

})();