(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    
    $stateProvider
      .state('signin', {
        url: '/signin',
        templateUrl: '../views/auth/login.html',
        controller: 'AuthController',
        controllerAs: 'Auth'
      })
      .state('forgot-password', {
        url: '/forgot-password',
        templateUrl: '../views/auth/forgot-password-form.html',
        controller: 'ForgotPasswordController',
        controllerAs: 'ForgotCtrl',
        authenticate: false
      })
      .state('reset-link-sent', {
        url: '/reset-link-sent',
        templateUrl: '../views/auth/reset-link-sent.html',
        authenticate: false
      })
      .state('password-reset', {
        url: '/password-reset/:token',
        templateUrl: '../views/auth/reset-password-form.html',
        controller: 'ResetPasswordController',
        controllerAs: 'ResetCtrl',
        authenticate: false
      })

  }

})();