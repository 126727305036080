(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('notAuthenticated', notAuthenticated);

  notAuthenticated.$inject = ['$q', '$injector'];

  function notAuthenticated($q, $injector) {
    return {
      responseError: responseError
    };

    function responseError(rejection) {
      if (rejection.status === 401) {
        var $state = $injector.get('$state');
        var authService = $injector.get('authenticateService');

        authService.logout();
        $state.go('signin');
      }

      return $q.reject(rejection);
    }

  }

})();