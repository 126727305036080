(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ParticipantRecordController', ParticipantRecordController);

  ParticipantRecordController.$inject = ['$scope', '$stateParams', 'participantDataService', 'hexagonDataService', 'workingMemoryService'];

  function ParticipantRecordController($scope, $stateParams, participantDataService, hexagonDataService, workingMemoryService) {
    var vm = this;

    vm.record = {};

    vm.deleteHexagon = deleteHexagon;
    vm.deleteWorkingMemory = deleteWorkingMemory;
    vm.downloadHexagon = downloadHexagon;

    $scope.$on('participant-record-updated', function(e) {
      activate();
    });

    activate();

    function activate() {
      participantDataService.fetch($stateParams.id)
        .then(function(data) {
          vm.record = data.data;
          return vm.record;
        });
    }

    function deleteHexagon(hexagonId) {
      hexagonDataService.destroy(hexagonId)
        .then(function() {
          activate();
        });
    }

    function deleteWorkingMemory(memoryId) {
      workingMemoryService.destroy(memoryId)
        .then(function() {
          activate();
        });
    }

    function downloadHexagon(hexagonId) {
      hexagonDataService.download(hexagonId);
    }

  }

})();