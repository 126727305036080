(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];

  function config($stateProvider, $urlRouterProvider, $locationProvider) {

    $locationProvider.html5Mode(true);

    $urlRouterProvider.otherwise('/404');

    $stateProvider
      .state('/', {
        url: '/',
        templateUrl: '../views/pages/home.html'
      })
      .state('404', {
        url: '/404',
        templateUrl: '../views/error-pages/404.html'
      })
      .state('unauthorised', {
        url: '/unauthorised',
        templateUrl: '../views/error-pages/unauthorised.html'
      })

  }

})();