(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('roleDataService', roleDataService);

    roleDataService.$inject = ['$http'];

    function roleDataService($http) {

      return {
        fetch: fetch,
        setDefaultRole: setDefaultRole,
        show: show,
        store: store
      };

      function fetch() {
        return $http.get('/api/roles');
      }

      function setDefaultRole(id, type) {
        return $http.put('/api/roles/toggleDefaultRole', {role_id: id, type: type});
      }

      function show(id) {
        return $http.get('/api/roles/' + id);
      }

      function store(data) {
        return $http.post('/api/roles', data);
      }
    }
    
})();