(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('uniqueEmailValidator', uniqueEmailValidator);

  uniqueEmailValidator.$inject = ['$http', '$q'];

  function uniqueEmailValidator($http, $q) {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        ngModel.$asyncValidators.uniqueEmail = function(email) {
          var dfd = $q.defer();

          $http.get('/api/validation/email?email=' + email)
            .then(function(data) {
              if ( ! data.data.unique) {
                dfd.reject();
              } else {
                dfd.resolve();
              }
            });

          return dfd.promise;
        }
      }
    }
  }
})();