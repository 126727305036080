(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ResetPasswordController', ResetPasswordController);

  ResetPasswordController.$inject = ['$state', '$stateParams', 'passwordResetService'];

  function ResetPasswordController($state, $stateParams, passwordResetService) {
    var vm = this;

    // if we do not have a token 404 the url
    if (! $stateParams.token) $state.go('404');

    vm.submit = submit;

    function submit(isValid, formData) {
      if (! isValid) return;

      formData.token = $stateParams.token;

      passwordResetService.resetPassword(formData);
    }

  }

})();