(function() {
  angular
    .module('Genetics')
    .factory('participantDataService', participantDataService);

  participantDataService.$inject = ['$http'];

  function participantDataService($http) {

    return {
      fetch: fetch,
      filters: filters,
      search: search,
      store: store,
      updateDemographics: updateDemographics,
      updateIdentifiers: updateIdentifiers,
      updateMedical: updateMedical,
      updateUserDetails: updateUserDetails
    };


    function fetch(participant_id) {
      return $http.get('/api/participants/' + participant_id);
    }

    function filters() {
      return $http.get('/api/participants/filters')
    }

    function search(data) {
      return $http.post('/api/participants/search', data);
    }

    function store(data) {
      return $http.post('/api/participants', data);
    }

    function updateDemographics(id, data) {
      return $http.put('/api/participants/demographics/' + id, data);
    }

    function updateIdentifiers(id, data) {
      return $http.put('/api/participants/identifiers/' + id, data);
    }

    function updateMedical(id, data)
    {
      return $http.put('/api/participants/medical/' + id, data);
    }

    function updateUserDetails(id, data)
    {
      return $http.put('/api/participants/user/' + id, data);
    }

  }

})();