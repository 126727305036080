(function () {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {

    $stateProvider
      .state('participate', {
        abstract: true,
        url: '/participate',
        template: '<ui-view />'
      })
      .state('participate.display', {
        url: '/:id',
        templateUrl: '../views/participate/display.html',
        controller: 'DisplayQuestionnaireController',
        controllerAs: 'Q',
        authenticate: true,
        permission: 'participate-questionnaires'
      });
  }

})();