(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('permissionDataService', permissionDataService);

  permissionDataService.$inject = ['$http'];

  function permissionDataService($http) {
    
    var url = '/api/permissions';

    return {
      clean: clean,
      fetch: fetch,
      grant: grant,
      toggle: toggle, 
      populate: populate 
    };


    function clean() {
      return $http.get(url + '/clean');
    }

    function fetch() {
      return $http.get(url);
    }

    function grant(role) {
      return $http.post(url + '/grant', {'role_id': role});
    }

    function populate() {
      return $http.get(url + '/populate');
    }

    function toggle(role, permission) {
      return $http.post(url, {'role_id': role, 'permission_id': permission});
    }

  }

})();