(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('nativeEnglish', nativeEnglish);

  function nativeEnglish() {
    return function(value) {
      return {
        1: 'Yes',
        2: 'No'
      }[value];
    };
  }

})();