(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('UserRolesController', UserRolesController);

  UserRolesController.$inject = ['$stateParams', 'userDataService', 'roleDataService', 'toastr'];

  function UserRolesController($stateParams, userDataService, roleDataService, toastr) {
    var vm = this;

    vm.roles = [];
    vm.user = {};

    vm.hasRole = hasRole;
    vm.toggle = toggle;

    activate();

    function activate() {
      userDataService.show($stateParams.id)
        .then(function(res) {
          vm.user = res.data;
          return vm.user;
        });

      roleDataService.fetch()
        .then(function(res) {
          vm.roles = res.data;
          return vm.roles;
        });
    }

    function hasRole(roleId) {
      var has = false;
      angular.forEach(vm.user.roles, function(role) {
        if (role.id == roleId) has = true;
      });

      return has;
    }

    function toggle(userId, roleId) {
      userDataService.toggleRoles(userId, roleId)
        .then(function(res) {
          toastr.success(res.data.message);
        });
    }

  }

})();