(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('editInPlace', function() {
      return {
        restrict: 'A',
        scope: {
          data: '=editInPlace',
          onSave: '&'
        },
        templateUrl: '../views/qualifications/edit-in-place-text.html',
        link: function(scope) {
          scope.isEditing = false;

          scope.switchToEdit = function() {
            scope.editQualification = angular.copy(scope.data);
            scope.isEditing = true;
          };

          scope.switchToView = function() {
            scope.isEditing = false;
          };

          scope.update = function(isValid, data) {
            if ( ! isValid) return;

            scope.onSave({data: data});
            scope.isEditing = false;
          };
        }
      };
    });

})();