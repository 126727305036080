(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('participantFilter', filter);

  function filter() {

    /**
     * participants: array of participants
     * filterObt: object containing key/value pairs to be filtered against
     */
    return function(participants, filterObj) {
      var matches = [];
      var match;

      // determines if an object is actually an object
      function isObject(obj) {
        return obj === Object(obj) && !Array.isArray(obj);
      }

      function matchKeyValues(key, value) {
        if (Array.isArray(value)) {
          if (value.length > 0 && value.indexOf(key) < 0) match = false;
        } else {
          if (key !== value) match = false;
        }
      }

      function compare(ptp, key, value) {
        // if the participant item being compared is an array (such as gene alleles)
        // we need to 'flip' the key to value matching
        if (Array.isArray(ptp[key]) && value.length > 0) {
          angular.forEach(value, function(valueItem) {
            matchKeyValues(valueItem, ptp[key]);
          });
        } else {
          // else just see if participant.whatever matches the value
          matchKeyValues(ptp[key], value);
        }
      }

      // iterates through each item in the filterObj, calls itself recursively.
      function runFilter(ptp, filters) {
        for (var name in filters) {
          if (isObject(filters[name])) {
            runFilter(ptp[name], filters[name]);
          } else {
            compare(ptp, name, filters[name]);
          }
        }
      }

      // if filterObt not object just return all participants
      if (!isObject(filterObj)) {
        return participants;
      }

      // iterate through each participant
      angular.forEach(participants, function (ptp) {
        match = true; // we'll assume they match the criteria by default
        // run the filter
        runFilter(ptp, filterObj);
        // if do actually match criteria, add to the matches array
        if (match) {
          matches.push(ptp);
        }
      });

      return matches;
    };

  }

})();