(function() {
  'use strict';

  angular
    .module('SubScale')
    .controller('SubScaleCreateController', SubScaleCreateController);

  SubScaleCreateController.$inject = ['$state', 'subScaleDataService', 'questionnaireDataService', 'toastr'];

  function SubScaleCreateController($state, subScaleDataService, questionnaireDataService, toastr) {
    var ctrl = this;

    ctrl.questionnaires = []; // array of available questionnaires
    ctrl.questions = []; // array of the questions that makes up the questionnaire
    ctrl.form = {}; // form data
    ctrl.formError = false;

    ctrl.checkIncluded = checkIncluded;
    ctrl.removeUnchecked = removeUnchecked;
    ctrl.getQuestions = getQuestions;
    ctrl.submit = submit;

    activate();

    function activate() {
      return questionnaireDataService.fetch()
        .then(function(response) {
          return ctrl.questionnaires = response.data;
        });
    }

    function checkIncluded(index) {
      if (ctrl.form.questions[index].reverse === true) {
        ctrl.form.questions[index].include = true;
      } else {
        delete ctrl.form.questions[index].reverse;
      }
    }

    function removeUnchecked() {
      angular.forEach(ctrl.form.questions, function(item, idx) {
        if (item.include === false) {
          delete ctrl.form.questions[idx];
        }
      });

      ctrl.formError = ctrl.form.questions.length;
    }

    function getQuestions(qId) {
      return subScaleDataService.questions(qId)
        .then(function(response) {
          return ctrl.questions = response.data;
        });
    }

    function submit(isValid, formData) {
      if (! isValid || typeof formData.questions === 'undefined') {
        ctrl.formError = true;
        return;
      }

      subScaleDataService.save(formData)
        .then(function(response) {
          toastr.success(response.data.message);
          $state.go('sub-scales.index');
        }, function(error) {
          toastr.error(error.data.message);
        });
    }

  }

})();