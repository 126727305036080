(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('drinker', drinker);

  function drinker() {
    return function(value) {
      return {
        1: 'Yes',
        2: 'No',
        3: 'Prefer not to say',
        4: 'Not answered'
      }[value];
    };
  }

})();