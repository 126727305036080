(function() {
  'use strict';

  angular
    .module('Data')
    .controller('DataIndexController', DataIndexController);
  
  DataIndexController.$inject = ['$timeout', 'dataService', 'ethnicityDataService', 'qualificationDataService', 'participantsDropdowns', 'geneService'];

  function DataIndexController($timeout, dataService, ethnicityDataService, qualificationDataService, participantsDropdowns, geneService) {
    var ctrl = this;

    ctrl.drinker = participantsDropdowns.drinker();
    ctrl.ethnicities = [];
    ctrl.filters = {};
    ctrl.genders = participantsDropdowns.gender();
    ctrl.genes = [];
    ctrl.filteredParticipants = dataService.filteredParticipants;
    ctrl.participants = dataService.participants;
    ctrl.qualifications = [];
    ctrl.smoker = participantsDropdowns.smoker();

    ctrl.runFilter = function() {
      $timeout(function() {
        dataService.runFilter(ctrl.filters);
      }, 400);
    };

    activate();

    function activate() {
      dataService.init();
      
      ethnicityDataService.fetch()
        .then(function(response) {
          ctrl.ethnicities = response.data;
        });

      qualificationDataService.fetch()
        .then(function(response) {
          ctrl.qualifications = response.data;
        });

      ctrl.genes = geneService.all();
    }


  }

})();