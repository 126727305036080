(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('broadcastFormErrors', broadcastFormErrors);

  broadcastFormErrors.$inject = ['$q', '$rootScope'];

  function broadcastFormErrors($q, $rootScope) {
    return {
      responseError: responseError
    };

    function responseError(rejection) {
      if (rejection.status === 422) {
        $rootScope.$broadcast('422-form-errors', rejection.data);
      }

      return $q.reject(rejection);
    }
  }

})();