(function() {
  'use strict';

  angular
    .module('Data')
    .config(routes);

  routes.$inject = ['$stateProvider'];

  function routes($stateProvider) {

    $stateProvider
      .state('data', {
        abstract: true,
        url: '/data',
        template: '<ui-view />'
      })
      .state('data.index', {
        url: '',
        controller: 'DataIndexController',
        controllerAs: 'DataCtrl',
        templateUrl: '../views/data/index.html',
        authenticate: true,
        permission: 'data-use'
      });

  }

})();