(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .factory('geneTypesService', geneTypesService);

  geneTypesService.$inject = ['GeneType', 'toastr'];

  function geneTypesService (GeneType, toastr) {
    var service = this;

    service.list = [];

    service.all = function() {
      if (service.list.length) return service.list;

      return GeneType.query(function(response) {
        service.list = response;
        return service.list;
      });
    };

    service.add = function(type) {
      var newType = new GeneType(type);
      newType.$save(function(response) {
        service.list.push(response);
      });
    };

    service.remove = function(type) {
      type.$delete(function(response) {
        var index = service.list.indexOf(type);
        if (index >= 0) service.list.splice(index, 1);
      });
    };

    service.update = function(geneType) {
      geneType.$update(function(response) {
        service.list.map(function(item, index) {
          if (item.id === response.id) {
            service.list[index] = response;
          }
        });
        toastr.success('Gene Type updated.');
      });
    };

    return service;
  }

})();