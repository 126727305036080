(function() {
  'use strict';

  angular
    .module('Genetics')
    .run(run);

  run.$inject = ['$rootScope', '$state', 'permissionsService', 'authenticateService'];

  function run($rootScope, $state, permissionsService, authenticateService) {

    $rootScope.$on('$stateChangeStart', function(event, next) {
      if (next.permission && authenticateService.isAuthenticated()) {
        if (! permissionsService.hasPermission(next.permission)) {
          event.preventDefault();
          $state.go('unauthorised');
        }
      }
    });
  }

})();