(function() {
  'use strict';

  angular
    .module('Hexagon')
    .config(routes);

  routes.$inject = ['$stateProvider'];

  function routes($stateProvider) {

    $stateProvider
      .state('hexagon', {
        abstract: true,
        url: '/hexagon',
        template: '<ui-view />'
      })
      .state('hexagon.upload', {
        url: '/upload/:id',
        templateUrl: '../views/hexagon/upload.html',
        controller: 'HexagonUploadController',
        controllerAs: 'UploadCtrl',
        authenticate: true,
        permission: 'hexagon-upload'
      });
  }

})();