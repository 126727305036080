(function() {
  'use strict';
  
  angular
    .module('Genetics')
    .controller('IdentifiersEditController', IdentifiersEditController);
    
  IdentifiersEditController.$inject = ['$scope', '$uibModal', 'toastr'];
  
  function IdentifiersEditController($scope, $uibModal, toastr) {
    var vm = this;

    vm.edit = edit;

    function edit(participant) {

      var edit = $uibModal.open({
        animation: true,
        templateUrl: '../views/participants/edit-identifiers-modal.html',
        controller: function($scope, $uibModalInstance, participantDataService, data) {
          $scope.data = data;

          $scope.dismiss = function() {
            $uibModalInstance.dismiss('cancel');
          };

          $scope.update = function(isValid, data) {
            if (! isValid) return;

            participantDataService.updateIdentifiers(participant.id, data)
              .then(function() {
                $uibModalInstance.close();
              });
          };

        },
        resolve: {
          data: function() {
            return angular.copy(participant);
          }
        }
      });

      edit.result
        .then(function() {
          $scope.$emit('participant-record-updated');
          toastr.success('Participant identifiers updated');
        });
    }
    
  }  

})();