(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .config(geneRoutes);

  geneRoutes.$inject = ['$stateProvider'];

  function geneRoutes($stateProvider) {

    $stateProvider
      .state('genes', {
        abstract: true,
        url: '/genes',
        template: '<ui-view />'
      })
      .state('genes.index', {
        url: '',
        templateUrl: '../views/genes/index.html',
        controller: 'GeneListController',
        controllerAs: 'GeneListCtrl',
        authenticate: true,
        permission: 'genes-view'
      })
      .state('genes.upload', {
        url: '/upload',
        templateUrl: '../views/genes/upload.html',
        controller: 'GeneUploadController',
        controllerAs: 'UploadCtrl',
        authenticate: true,
        permission: 'genes-upload'
      });

  }

})();