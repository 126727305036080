(function() {
  'use strict';

  angular
    .module('Wasi')
    .controller('WasiUploadController', WasiUploadController);

    WasiUploadController.$inject = ['$state', '$stateParams', 'Upload', 'toastr'];

    function WasiUploadController($state, $stateParams, Upload, toastr) {
      var ctrl = this;

      ctrl.fileErrors = [];
      ctrl.participant_id = $stateParams.id;
      ctrl.progress = 0;
      ctrl.upload = upload;

      function upload(isValid, file) {
        if (! isValid) return;

        Upload.upload({
          url: '/api/wasi/upload',
          data: {
            participant_id: ctrl.participant_id,
            file: file
          }
        }).progress(function(evt) {
          ctrl.progress = parseInt(100.0 * evt.loaded/evt.total);
        }).success(function() {
          toastr.success('WASI Data Uploaded');
          $state.go('participants.show', { id: ctrl.participant_id });
        }).error(function(error) {
          ctrl.fileErrors = error.missing;
          toastr.error(error.message, "Excel File Error");
        });

      }

    }
    
})();