(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('phoneType', phoneType);

  function phoneType() {
    return function(value) {
      return {
        1: 'Mobile',
        2: 'Home',
        3: 'Work',
      }[value];
    };
  }

})();