/**
 * $http Interceptor service that prevents duplicate form submissions
 * Assumptions: picks up all POST requests and request.data objects are identical
 * Relies on angular.equals to compare the request.data objects
 *
 * $httpProvider.interceptors.push('catchDuplicateRequest');
 */

(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('catchDuplicateRequest', catchDuplicateRequest);

  catchDuplicateRequest.$inject = ['$q', '$injector'];

  function catchDuplicateRequest($q, $injector) {
    return {
      request: request
    };

    function request(requestObj) {

      if (requestObj.method == 'POST') {
        return comparePendingRequests(requestObj) ? generateFakeResponse(requestObj) : requestObj;
      }

      return requestObj;
    }

    // filter through pending request data objects looking for matches
    function comparePendingRequests(currentRequestObj) {

      var $http = $injector.get('$http');

      var duplicate = $http.pendingRequests.filter(function(pendingRequest) {
        return angular.equals(pendingRequest.data, currentRequestObj.data);
      });

      return duplicate.length > 0;
    }

    // fake response promise, as must return a promise obj
    function generateFakeResponse(request) {

      var response = {
        data: {},
        headers: {},
        status: 499,
        config: request
      };

      return $q.reject(response);
    }

  }

})();