(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('handedness', handedness);

  function handedness() {
    return function(value) {
      return {
        1: 'Right',
        2: 'Left'
      }[value];
    };
  }

})();