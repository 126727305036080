(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('BasicDetailsEditController', BasicDetailsEditController);

  BasicDetailsEditController.$inject = ['$scope', '$uibModal', 'toastr'];

  function BasicDetailsEditController($scope, $uibModal, toastr) {
    var vm = this;

    vm.edit = edit;

    function edit(participant) {

      var edit = $uibModal.open({
        animation: true,
        templateUrl: '../views/participants/edit-basic-details-modal.html',
        resolve: {
          data: function() {
            return {
              id: participant.id,
              user: angular.copy(participant.user)
            }
          }
        },
        controller: function($scope, $uibModalInstance, participantDataService, data) {
          $scope.data = data.user;

          $scope.dismiss = function() {
            $uibModalInstance.dismiss();
          };

          $scope.update = function(isValid, user) {
            if (! isValid) return;

            participantDataService.updateUserDetails(data.id, user)
              .then(function() {
                $uibModalInstance.close();
              });
          };

        }
      });

      edit.result
        .then(function() {
          $scope.$emit('participant-record-updated');
          toastr.success('Participant details updated.');
        });

    }

  }

})();