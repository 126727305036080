(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('EthnicityIndexController', EthnicityIndexController);

  EthnicityIndexController.$inject = ['$scope', 'ethnicityDataService', 'toastr'];

  function EthnicityIndexController($scope, ethnicityDataService, toastr) {
    var vm = this;

    vm.ethnicitiesList = [];

    vm.destroy = destroy;
    vm.update = update;

    $scope.$on('ethnic-group-created', function(e) {
      activate();
    });

    activate();

    function activate() {
      ethnicityDataService.fetch()
        .then(function(data) {
          vm.ethnicitiesList = data.data;
          return vm.ethnicitiesList;
        });
    }

    function destroy(ethnicity_id) {
      ethnicityDataService.destroy(ethnicity_id)
        .then(function() {
          activate();
          toastr.success('Ethnic class record deleted.');
        });
    }

    function update(data) {
      ethnicityDataService.update(data)
        .then(function() {
          activate();
          toastr.success('Ethnic group updated.');
        });
    }

  }

})();