(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('accountDataService', accountDataService);

  accountDataService.$inject = ['$http'];

  function accountDataService($http) {
    return {
      changePassword: changePassword,
      fetchAvailableQuestionnaires: fetchAvailableQuestionnaires,
      fetchCompletedQuestionnaires: fetchCompletedQuestionnaires,
      fetchUserDetails: fetchUserDetails
    };

    function changePassword(data) {
      return $http.put('/api/account/change-password', data);
    }

    function fetchAvailableQuestionnaires() {
      return $http.get('/api/account/questionnaires');
    }

    function fetchCompletedQuestionnaires()
    {
      return $http.get('/api/account/completed');
    }

    function fetchUserDetails() {
      return $http.get('/api/account/user');
    }
  }

})();