(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .factory('Gene', GeneModel);

  GeneModel.$inject = ['$resource', 'Allele'];

  function GeneModel($resource, Allele) {

    return $resource('/api/genes/:id', {id: '@id'}, {
      'update': { method: 'PUT', params: {id: '@id'} }
    });

  }

})();