(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {

    $stateProvider
      .state('participants', {
        url: '/participants',
        abstract: true,
        template: '<ui-view />'
      })
      .state('participants.index', {
        url: '',
        templateUrl: '../views/participants/index.html',
        controller: 'ParticipantIndexController',
        controllerAs: 'participant',
        authenticate: true,
        permission: 'participants-search'
      })
      .state('participants.create', {
        url: '/create',
        templateUrl: '../views/participants/create.html',
        controller: 'ParticipantCreateController',
        controllerAs: 'participant',
        authenticate: true,
        permission: 'participants-create'
      })
      .state('participants.show', {
        url: '/show/:id',
        templateUrl: '../views/participants/show.html',
        controller: 'ParticipantRecordController',
        controllerAs: 'participant',
        authenticate: true,
        permission: 'participants-view'
      })
      .state('participants.filter', {
        url: '/filter',
        templateUrl: '../views/participants/filter.html',
        controller: 'ParticipantFilterController',
        controllerAs: 'filter',
        authenticate: true,
        permission: 'participants-filter'
      })
      .state('participants.upload', {
        url: '/upload',
        templateUrl: '../views/participants/upload.html',
        controller: 'ParticipantUploadController',
        controllerAs: 'UploadCtrl',
        authenticate: true,
        permission: 'participants-create'
      });

  }

})();