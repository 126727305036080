(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$httpProvider', 'jwtInterceptorProvider'];

  function config($httpProvider, jwtInterceptorProvider) {

    jwtInterceptorProvider.tokenGetter = ['$http', 'authenticateService', function($http, authenticateService) {

      if (authenticateService.isAuthenticated() && authenticateService.hasTokenExpired()) {

        return $http({
          url: '/api/authenticate/refresh',
          skipAuthorization: true,
          method: 'POST',
          data: {
            token: authenticateService.getToken()
          }
        }).then(function(data) {
          authenticateService.setToken(data);
          return data.data.token;
        });

      } else {
        return authenticateService.getToken();
      }


    }];

    $httpProvider.interceptors.push('jwtInterceptor');
  }

})();