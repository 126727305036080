(function() {
  'use strict';

  angular
    .module('Data')
    .controller('DataSubScaleController', DataSubScaleController);

  DataSubScaleController.$inject = ['$scope', 'dataService', 'toastr'];

  function DataSubScaleController($scope, dataService, toastr) {
    var ctrl = this;

    ctrl.scales = [];

    ctrl.requestData = requestData;

    $scope.$on('filtered-list-changed', function() {
      dataService.fetchScales()
        .then(function(response) {
          ctrl.scales = response.data;
        });
    });

    function requestData(sId) {
      dataService.requestScale(sId)
        .then(function(response) {
          var message = response.data.message;
          toastr.success(message);
        });
    }

  }

})();