(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('RoleShowController', RoleShowController);

    RoleShowController.$inject = ['$stateParams', 'roleDataService', 'permissionDataService', 'toastr'];

    function RoleShowController($stateParams, roleDataService, permissionDataService, toastr) {
      var vm = this;

      vm.role = []; // role model
      vm.permissions = []; // permissions model
      vm.selected = {}; // role's permissions

      vm.grantAllPermissions = grantAllPermissions;
      vm.togglePermission = togglePermission;

      activate();

      function activate() {
        roleDataService.show($stateParams.id)
          .then(function(data) {
            vm.role = data.data;

            angular.forEach(vm.role.permissions, function(permission) {
              vm.selected[permission.id] = true;
            });

            return vm.role;
          });

        permissionDataService.fetch()
          .then(function(data) {
            vm.permissions = data.data;
            return vm.permissions;
          });
      }

      function grantAllPermissions(role) {
        permissionDataService.grant(role)
          .then(function() {
            activate();
            toastr.success('All Permissions Granted');
          });
      }

      function togglePermission(role, permission) {
        permissionDataService.toggle(role, permission)
          .then(function(data) {
            toastr.success('Role Permissions Updated.');
          });
      }
    }
    
})();