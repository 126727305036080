(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('EthnicityCreateController', EthnicityCreateController);

  EthnicityCreateController.$inject = ['$scope', 'ethnicityDataService', 'toastr'];

  function EthnicityCreateController($scope, ethnicityDataService, toastr) {
    var vm = this;

    vm.data = {};

    vm.store = store;

    function store(isValid, data) {
      if ( ! isValid) return;

      ethnicityDataService.store(data)
        .then(function() {
          toastr.success('Ethnic grouped added.');

          vm.data = {};

          $scope.ethForm.$setPristine();
          $scope.ethForm.$setUntouched();

          $scope.$emit('ethnic-group-created');
        });
    }

  }

})();