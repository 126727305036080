(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('hasPermission', hasPermission);

  hasPermission.$inject = ['permissionsService'];

  function hasPermission(permissionsService) {

    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        var permission = attrs.hasPermission.trim();

        if (! permission) throw new Error("No permission value passed.");

        if (! permissionsService.hasPermission(permission)) {
          element.remove();
        }
      }
    }

  }

})();