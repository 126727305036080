(function() {
  'use strict';

  angular
    .module('WorkingMemory',
      [
        'ui.router',
        'ngAnimate',
        'ngSanitize',
        'ngMessages',
        'toastr'
    ]);

})();