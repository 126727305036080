(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ApplicationSummaryController', ApplicationSummaryController);

  ApplicationSummaryController.$inject = ['$scope', '$state', 'applicationDataService'];

  function ApplicationSummaryController($scope, $state, applicationDataService) {
    var vm = this;
    vm.tab = 1;
    vm.approvedApplications = [];
    vm.approvedCount = 0;
    vm.rejectedApplications = [];
    vm.rejectedCount = 0;

    activateApproved();
    activateRejected();

    vm.isSelected = isSelected;
    vm.selectTab = selectTab;

    function activateApproved() {
      applicationDataService.approved()
        .then(function(res) {
          vm.approvedApplications = res.data.approved;
          vm.approvedCount = res.data.count;
          return vm.approvedApplications;
        });
    }

    function activateRejected() {
      applicationDataService.rejected()
        .then(function(res) {
          vm.rejectedApplications = res.data.rejected;
          vm.rejectedCount = res.data.count;
          return vm.rejectedApplications;
        });
    }

    function isSelected(checkTab) {
      return vm.tab === checkTab;
    }

    function selectTab(setTab) {
      vm.tab = setTab;
    }

    $scope.$on('application-rejected', function(e) {
      activateRejected();
    });

    $scope.$on('application-approved', function(e) {
      activateApproved();
    });

  }

})();