(function() {
    'use strict';

    angular
      .module('Genetics')
      .controller('RoleIndexController', RoleIndexController);

    RoleIndexController.$inject = ['$scope', 'roleDataService', 'toastr'];

    function RoleIndexController($scope, roleDataService, toastr) {
      var vm = this;

      vm.roles = [];
      vm.noDefaultReseacherRole = false;
      vm.noDefaultParticipantRole = false;

      vm.toggleDefaultRole = toggleDefaultRole;

      $scope.$on('role-created', function(e) {
        activate();
      });

      activate();

      function activate() {
        roleDataService.fetch()
          .then(function(data) {
            vm.roles = data.data;
            checkDefaultRoles();
            return vm.roles;
          });
      }

      function checkDefaultRoles() {
        var researcher = true;
        var participant = true;

        angular.forEach(vm.roles, function(role) {
          if (role.is_researcher_role) researcher = false;
          if (role.is_participant_role) participant = false;
        });

        vm.noDefaultParticipantRole = participant;
        vm.noDefaultReseacherRole = researcher;
      }

      function toggleDefaultRole(id, type) {
        roleDataService.setDefaultRole(id, type)
          .then(function(data) {
            activate();
            toastr.success(data.data.message);
          });
      }

    }

})();