(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('passwordResetService', passwordResetService);

  passwordResetService.$inject = ['$http', '$state', 'authenticateService', 'toastr'];

  function passwordResetService($http, $state, authenticateService, toastr) {
    return {
      requestReset: requestReset,
      resetPassword: resetPassword
    }

    function requestReset(data) {
      return $http.post('/api/forgot-password', data);
    }

    function resetPassword(formData) {
      $http.post('/api/reset-password', formData)
        .then(function(response) {
          // set user token
          authenticateService.setToken(response);
          // flash success message - but should be obvious
          toastr.success('Your password has been reset. We have signed you in!');
          $state.go('account');
        }, function(error) {
          toastr.error(error.data.error);
        });
    }

  }

})();