(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ApplicationApplyController', ApplicationApplyController);

    ApplicationApplyController.$inject = ['$state', 'applicationDataService'];

    function ApplicationApplyController($state, applicationDataService) {
      var vm = this;

      vm.data = {};

      vm.apply = apply;

      function apply(isValid, data) {
        if ( ! isValid) return;

        applicationDataService.save(data)
          .then(function(data) {
            $state.go('applications.status', {'id': data.data.application_id});
          });
      }
    }
    
})();