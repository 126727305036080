(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ParticipantFilterController', ParticipantFilterController);

  ParticipantFilterController.$inject = ['participantDataService', 'toastr'];

  function ParticipantFilterController(participantDataService, toastr) {
    var vm = this;

    vm.filterOptions = {};
    vm.data = {};

    vm.submit = submit;

    activate();

    function activate() {
      participantDataService.filters()
        .then(function(data) {
          vm.filterOptions = data.data;
          return vm.filterOptions;
        });
    }

    function submit(isValid, data) {
      if ( ! isValid) return;

      console.log(data);
    }


  }

})();