(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    
    $stateProvider
      .state('applications', {
        abstract: true, 
        url: '/applications',
        template: '<ui-view />'
      })
      .state('applications.index', {
        url: '',
        templateUrl: '../views/applications/index.html',
        controller: 'ApplicationIndexController',
        controllerAs: 'IndexCtrl',
        authenticate: true,
        permission: 'application-pending'
      })
      .state('applications.status', {
        url: '/status/{id}',
        templateUrl: '../views/applications/status.html',
        controller: 'ApplicationStatusController',
        controllerAs: 'StatusCtrl'
      })
      .state('apply', {
        url: '/apply',
        templateUrl: ' ../views/applications/apply.html',
        controller: 'ApplicationApplyController',
        controllerAs: 'ApplyCtrl'
      });
  }

})();