(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('authenticateService', authenticateService);

  authenticateService.$inject = ['$rootScope', '$http', '$state', 'aiStorage', 'jwtHelper', 'toastr'];

  function authenticateService($rootScope, $http, $state, aiStorage, jwtHelper, toastr) {

    return {
      getToken: getToken,
      getUser: getUser,
      hasTokenExpired: hasTokenExpired,
      isAuthenticated: isAuthenticated,
      login: login,
      logout: logout,
      setToken: setToken
    };

    function getToken() {
      return aiStorage.get('auth_jwt');
    }

    function getUser() {
      return jwtHelper.decodeToken(getToken());
    }

    function hasTokenExpired() {
      return jwtHelper.isTokenExpired(getToken());
    }

    function isAuthenticated() {
      return getToken() || false;
    }

    function login(credentials, state) {
      $http.post('/api/authenticate', credentials)
        .then(function(data) {
          // set user token
          setToken(data);
          // flash success message - but should be obvious
          toastr.success('You are now Signed In');
          // redirect to original route if details on $rootScope
          if ($rootScope.returnToState != null) {
            $state.go($rootScope.returnToState, $rootScope.returnToStateParams);
          } else {
            $state.go(state);
          }
        }, function(err) {
          toastr.error(err.data.error);
        })
    }

    function logout() {
      aiStorage.remove('auth_jwt');
      aiStorage.remove('permissions');
    }

    function setToken(data) {
      aiStorage.set('auth_jwt', data.data.token);

      if (data.data.permissions) {
        aiStorage.set('permissions', data.data.permissions);
      }
    }

  }

})();