(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('researcherDataService', researcherDataService);

  researcherDataService.$inject = ['$http'];

  function researcherDataService($http) {

    return {
      save: save
    };

    function save(data) {
      return $http.post('/api/apply', data);
    }

  }

})();