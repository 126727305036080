(function() {
  'use strict';

  angular
    .module('SubScale')
    .factory('subScaleDataService', subScaleDataService);

  subScaleDataService.$inject = ['$http', 'toastr'];

  function subScaleDataService($http, toastr) {
    var url = '/api/sub-scales/';

    return {
      destroy: deleteScale,
      fetch: fetchAll,
      find: find,
      questions: getQuestions,
      save: saveSubscale,
      update: update
    };

    function deleteScale(scaleId) {
      return $http.delete(url + scaleId);
    }

    function fetchAll() {
      return $http.get(url);
    }

    function find(scaleId) {
      return $http.get(url + scaleId);
    }

    function getQuestions(questionnaireId) {
      return $http.get(url + 'questions/' + questionnaireId);
    }

    function saveSubscale(data) {
      return $http.post(url, data);
    }

    function update(id, data) {
      return $http.put(url + id, data);
    }

  }

})();