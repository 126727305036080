(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .controller('GeneFormController', GeneFormController);

  GeneFormController.$inject = ['$uibModalInstance', 'geneService', 'geneTypesService', 'editGene'];

  function GeneFormController ($uibModalInstance, geneService, geneTypesService, editGene) {
    var vm = this;
    vm.types = geneTypesService.all();

    if (editGene) {
      vm.gene = editGene;
    } else {
      vm.gene = {
        alleles: [{}]
      };
    }


    vm.addAllele = addAllele;
    vm.dismiss = dismiss;
    vm.removeAllele = removeAllele;
    vm.save = saveGene;

    function addAllele () {
      vm.gene.alleles.push({});
    }

    function dismiss() {
      $uibModalInstance.dismiss();
    }

    function removeAllele (allele) {
      vm.gene.alleles = vm.gene.alleles.filter(function(el) {
        return (el.value !== allele.value || el.allele !== allele.allele);
      });

      if (vm.gene.alleles.length === 0) addAllele();
    }

    function saveGene (isValid, gene) {
      if (! isValid) return;

      if (gene.id) {
        geneService.update(gene)
          .then(function() {
            vm.dismiss();
          });
      } else {
        geneService.save(gene)
          .then(function() {
            vm.dismiss();
          });
      }
    }

  }

})();