(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('passwordMatchValidator', passwordMatchValidator);

  passwordMatchValidator.$inject = [];

  function passwordMatchValidator() {
    return {
      require: 'ngModel',
      scope: {
        otherModelValue: "=passwordMatchValidator"
      },
      link: function(scope, element, attributes, ngModel) {
        ngModel.$validators.passwordMatch = function(modelValue) {
          return modelValue == scope.otherModelValue;
        };

        scope.$watch("otherModelValue", function() {
          ngModel.$validate();
        });

      }
    }
  }

})();