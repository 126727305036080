(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('geneAllelesValidator', geneAllelesValidator);

  geneAllelesValidator.$inject = [];

  function geneAllelesValidator() {
    return {
      require: 'ngModel',
      scope: {
        allelesArray: "=geneAllelesValidator"
      },
      link: function(scope, elem, attr, ngModel) {
        var nonEmpty = [];

        scope.$watch("allelesArray", function() {
          nonEmpty = scope.allelesArray.filter(function(item) {
            return angular.isDefined(item.allele) && angular.isDefined(item.value);
          });
          ngModel.$validate();
        }, true);

        ngModel.$validators.required = function(modelValue) {

          if (nonEmpty.length === 0) {
            ngModel.$setValidity('allelesRequired', false);
          } else {
            ngModel.$setValidity('allelesRequired', true);
          }
          // return as string hack, as otherwise seems to be fecked.
          return String(modelValue);
        };
      }
    };
  }

})();