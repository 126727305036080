(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ParticipantIndexController', ParticipantIndexController);

  ParticipantIndexController.$inject = ['participantDataService'];

  function ParticipantIndexController(participantDataService) {
    var vm = this;

    vm.loading = false;
    vm.participants = [];
    vm.searched = false;

    vm.search = search;

    function search(isValid, data) {
      if ( ! isValid) return;

      vm.loading = true;

      participantDataService.search(data)
        .then(function(data) {
          vm.participants = data.data;
          vm.loading = false;
          vm.searched = true;
          return vm.participants;
        });
    }

  } // end Participant Controller

})();