(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('account', {
        'url': '/account',
        'templateUrl': '../views/account/default.html',
        'controller': 'AccountController',
        'controllerAs': 'Account',
        'authenticate': true
      });
  }

})();