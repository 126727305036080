(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('AccountController', AccountController);

    AccountController.$inject = ['accountDataService'];

    function AccountController(accountDataService) {
      var vm = this;

      vm.completed = false;
      vm.questionnaires = false;
      vm.user = {};
      
      activate();

      function activate() {
        accountDataService.fetchUserDetails()
          .then(function(response) {
            vm.user = response.data;

            if (vm.user.is_participant) {
              accountDataService.fetchAvailableQuestionnaires()
                .then(function(response) {
                  vm.questionnaires = response.data;
                  return accountDataService.fetchCompletedQuestionnaires();
                })
                .then(function(response) {
                  vm.completed = response.data;
                });
            }
          });
      }

    }
    
})();