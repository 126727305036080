(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('QuestionnaireEditController', QuestionnaireEditController);

  QuestionnaireEditController.$inject = ['$scope', '$uibModal', 'toastr'];

  function QuestionnaireEditController($scope, $uibModal, toastr) {
    var vm = this;

    vm.edit = edit;

    function edit(q) {

      var editModal = $uibModal.open({
        animation: true,
        templateUrl: 'views/questionnaires/edit.html',
        resolve: {
          data: function() {
            return angular.copy(q);
          }
        },
        controller: function($scope, $uibModalInstance, questionnaireDataService, data) {
          $scope.data = data;

          $scope.dismiss = function() {
            $uibModalInstance.dismiss();
          };

          $scope.update = function(isValid, q) {
            if (! isValid) return;

            questionnaireDataService.update(q)
              .then(function() {
                $uibModalInstance.close();
              });
          };

        }
      });

      editModal.result
        .then(function() {
          $scope.$emit('questionnaire-updated');
          toastr.success('Questionnaire details updated.');
        });

    }

  }

})();