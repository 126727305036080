(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('GlobalController', GlobalController);

    GlobalController.$inject = ['$rootScope', 'authenticateService', 'toastr'];

    function GlobalController($rootScope, authenticateService, toastr) {
      var vm = this;

      $rootScope.$on('422-form-errors', function(event, errors) {
        angular.forEach(errors, function(err) {
          if (err.constructor === Array) {
            toastr.error(err[0]);
          } else {
            toastr.error(err);
          }
        });
      });

    }
    
})();