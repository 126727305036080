(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('questionnaireDataService', questionnaireDataService);

  questionnaireDataService.$inject = ['$http', '$q'];

  function questionnaireDataService($http, $q) {

    return {
      download: download,
      fetch: fetch,
      getFileList: getFileList,
      publish: publish,
      store: store,
      update: update
    };


    function download(id){
      var dfd = $q.defer();
      var defaultFileName = 'download';

      $http.get('/api/questionnaires/download/' + id, {responseType: "arraybuffer"})
        .success(function (data, status, headers) {
          var type = headers('Content-Type');
          var disposition = headers('Content-Disposition');

          if (disposition) {
            var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
            if (match[1])
              defaultFileName = match[1];
          }

          defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
          var blob = new Blob([data], { type: type });
          saveAs(blob, defaultFileName);

          dfd.resolve(defaultFileName);
        })
        .error(function (data, status) {
          dfd.reject();
      });

      return dfd.promise;
    }

    function fetch() {
      return $http.get('/api/questionnaires');
    }

    function getFileList() {
      return $http.get('/api/questionnaires/files');
    }

    function publish(id) {
      return $http.get('/api/questionnaires/published/' + id);
    }

    function store(data) {
      return $http.post('/api/questionnaires', data);
    }

    function update(data) {
      return $http.put('/api/questionnaires/' + data.id, data);
    }

  }

})();