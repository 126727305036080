(function() {
  'use strict';

  angular
    .module('SubScale')
    .controller('SubScaleIndexController', SubScaleIndexController);

  SubScaleIndexController.$inject = ['subScaleDataService'];

  function SubScaleIndexController(subScaleDataService) {
    var ctrl = this;

    ctrl.list = [];

    ctrl.destroy = destroy;

    activate();

    function activate() {
      return subScaleDataService.fetch()
        .then(function(response) {
          return ctrl.list = response.data;
        });
    }

    function destroy(scaleId) {
      subScaleDataService.destroy(scaleId)
        .then(function(response) {
          activate();
        });
    }

  }

})();