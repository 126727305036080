(function() {
  'use strict';

  angular
  .module('Hexagon',
    [
    'ui.router',
    'toastr'
    ]);

})();