(function() {
  'use strict';
  
  angular
    .module('Genetics')
    .controller('ApplicationRenewController', ApplicationRenewController);
    
  ApplicationRenewController.$inject = ['$uibModal', 'toastr'];
  
  function ApplicationRenewController($uibModal, toastr) {
    var ctrl = this;

    ctrl.apply = function() {

      var renewModal = $uibModal.open({
        animation: true,
        templateUrl: '../views/applications/renew-modal.html',
        controller: function($scope, $uibModalInstance, applicationDataService) {

          $scope.submit = function(isValid, data) {
            if (! isValid) return;

            applicationDataService.renew(data)
              .then(function() {
                $uibModalInstance.close();
              });
          };

          $scope.dismiss = function() {
            $uibModalInstance.dismiss();
          };

        }
      });

      renewModal.result
        .then(function() {
          toastr.success('Renewal request receieved.');
        });
    };

  }  

})();