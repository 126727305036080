(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ApplicationStatusController', ApplicationStatusController);

    ApplicationStatusController.$inject = ['$stateParams', 'applicationDataService'];

    function ApplicationStatusController($stateParams, applicationDataService) {
      var vm = this;

      vm.application = {};

      activate();

      function activate() {
        applicationDataService.retrieve($stateParams.id)
          .then(function(data) {
            vm.application = data.data;
            return vm.application;
          });
      }
    }
    
})();