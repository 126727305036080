(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('AuthController', AuthController);

    AuthController.$inject = ['$state', 'authenticateService'];

    function AuthController($state, authenticateService) {
      var vm = this;

      vm.loggedIn = loggedIn;
      vm.login = login;
      vm.logout = logout;

      function loggedIn () {
        return authenticateService.isAuthenticated();
      }

      function login(isValid, data) {
        if (! isValid) return;

        authenticateService.login(data, 'account');
      }

      function logout() {
        authenticateService.logout();
        $state.go('signin');
      }

    }
    
})();