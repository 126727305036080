(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('QuestionnaireCreateController', QuestionnaireCreateController);

  QuestionnaireCreateController.$inject = ['$state', 'questionnaireDataService', 'toastr'];

  function QuestionnaireCreateController($state, questionnaireDataService, toastr) {
    var vm = this;

    vm.files = [];

    vm.submit = submit;

    activate();

    function activate() {
      questionnaireDataService.getFileList()
        .then(function(data) {
          vm.files = data.data;
          return vm.files;
        })
    }

    function submit(isValid, data) {
      if ( ! isValid) return;

      questionnaireDataService.store(data)
        .success(function() {
          toastr.success('New questionnaire successfully created. Be sure to publish.');
          $state.go('questionnaires.index');
        });

    }

  }

})();