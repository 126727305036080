(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('RoleCreateController', RoleCreateController);

    RoleCreateController.$inject = ['$scope', 'roleDataService', 'toastr'];

    function RoleCreateController($scope, roleDataService, toastr) {
      var vm = this;

      vm.data = {};

      vm.save = save;

      function save(isValid, data) {
        if ( ! isValid) return;

        roleDataService.store(data)
          .then(function() {
            toastr.success('Role Created');

            vm.data = {};

            $scope.form.$setPristine();
            $scope.form.$setUntouched();

            $scope.$emit('role-created');
          });
      }

    }

})();