(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('gender', gender);

  function gender() {
    return function(value) {
      return {
        1: 'Female',
        2: 'Male',
        3: 'Other',
        4: 'Not answered',
        5: 'Prefer not to say'
      }[value];
    };
  }

})();