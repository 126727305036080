(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('RecruitmentIndexController', RecruitmentIndexController);

  RecruitmentIndexController.$inject = ['$scope', 'recruitmentDataService', 'toastr'];

  function RecruitmentIndexController($scope, recruitmentDataService, toastr) {
    var vm = this;

    vm.sourceList = [];

    vm.destroy = destroy;
    vm.update = update;

    activate();

    function activate() {
      recruitmentDataService.getSources().then(function(data) {
        vm.sourceList = data.data;
        return vm.sourceList;
      });
    }

    function destroy(id) {
      recruitmentDataService.destroy(id)
        .then(function() {
          toastr.success('Recruitment source deleted.');
          activate();
        });
    }

    function update(data) {
      recruitmentDataService.update(data)
        .then(function() {
          activate();
          toastr.success('Recruitment Source has been updated.');
        });
    }

    $scope.$on('recruitment-source-created', function(e) {
      activate();
    });

  }

})();
