(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$compileProvider'];

  function config($compileProvider) {

    // disable debug info
    $compileProvider.debugInfoEnabled(true);

  }

})();