(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('formErrors', formErrors);

  function formErrors() {
    return {
      scope: {},
      controller: function($scope) {
        var vm = this;
        vm.errors = [];

        $scope.$on('422-form-errors', function(event, errors) {
          angular.forEach(errors, function(error) {
            vm.errors.push(error[0]);
          });
        });
      },
      controllerAs: 'ctrl',
      restrict: 'EA',
      template: '<div ng-show="ctrl.errors.length > 0" class="alert alert-danger"><p class="lead">Your form submission had the following errors:</p><ul><li ng-repeat="error in ctrl.errors">{{ error }}</li></ul></div>'
    };
  }

})();