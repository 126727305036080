(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('participantsDropdowns', participantsDropdowns);

  participantsDropdowns.$inject = [];

  function participantsDropdowns() {
    return {
      drinker: drinker,
      gender: gender,
      handedness: handedness,
      native: nativeSpeaker,
      smoker: smoker
    };

    function drinker() {
      return [
        {id: 1, label: 'Yes'},
        {id: 2, label: 'No'},
        {id: 3, label: 'Prefer not to say'},
        {id: 4, label: 'Not answered'}
      ];
    }

    function gender() {
      return [
        {id: 1, label: 'Female'},
        {id: 2, label: 'Male'},
        {id: 3, label: 'Other'},
        {id: 4, label: 'Not answered'},
        {id: 5, label: 'Prefer not to say'}
      ];
    }

    function handedness() {
      return [
        {id: 1, label: 'Right'},
        {id: 2, label: 'Left'}
      ];
    }

    function nativeSpeaker() {
      return [
        {id: 1, label: 'Yes'},
        {id: 2, label: 'No'}
      ];
    }

    function smoker() {
      return [
        {id: 1, label: 'Yes'},
        {id: 2, label: 'No'},
        {id: 3, label: 'Prefer not to say'},
        {id: 4, label: 'Not answered'}
      ];
    }
  }

})();