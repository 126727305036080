(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ChangePasswordController', ChangePasswordController);

  ChangePasswordController.$inject = ['$uibModal', 'toastr'];

  function ChangePasswordController($uibModal, toastr) {
    var vm = this;

    vm.changePassword = changePassword;

    function changePassword() {

      var changeModal = $uibModal.open({
        animation: true,
        templateUrl: '../views/account/change-password-modal.html',
        controller: function($scope, $uibModalInstance, accountDataService) {

          $scope.update = function(isValid, data) {
            if (! isValid) return;

            accountDataService.changePassword(data)
              .then(function() {
                $uibModalInstance.close();
              });
          };

          $scope.dismiss = function() {
            $uibModalInstance.dismiss();
          };

        }
      });

      changeModal.result
        .then(function() {
          toastr.success('Your password has been changed.')
        });
      
    }

  }

})();