(function() {
  'use strict';

  angular
    .module('Data')
    .controller('DataQuestionnaireController', DataQuestionnaireController);

  DataQuestionnaireController.$inject = ['$scope', 'dataService', 'toastr'];

  function DataQuestionnaireController($scope, dataService, toastr) {
    var ctrl = this;

    ctrl.requestData = processRequest;
    ctrl.questionnaires = [];

    $scope.$on('filtered-list-changed', function() {
      dataService.fetchQuestionnaires()
        .then(function(response) {
          ctrl.questionnaires = response.data;
        });
    });

    function processRequest(qId) {
      dataService.sendQuestionnaireData(qId)
        .then(function(response) {
          var message = response.data.message;
          toastr.success(message);
        });
    }

  }

})();