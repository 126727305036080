(function () {
  'use strict';

  angular
    .module('Data')
    .factory('dataService', dataService);

  dataService.$inject = ['$rootScope', '$http', '$filter'];

  function dataService($rootScope, $http, $filter) {
    var svc = this;

    svc.participants = [];
    svc.filteredParticipants = [];

    svc.init = function() {
      $http.get('/api/data/participants')
        .then(function(response) {
          angular.copy(response.data, svc.participants);
          changeFiltered(response.data);
        });
    };

    svc.fetchQuestionnaires = function() {
      return $http.post('/api/data/questionnaires', {
        data: svc.filteredParticipants
      });
    };

    svc.sendQuestionnaireData = function(qId) {
      return $http.post('/api/email-data/questionnaire/' + qId, {
        data: svc.filteredParticipants
      });
    };

    svc.fetchTasks = function() {
      return $http.post('/api/data/tasks', {
        data: svc.filteredParticipants
      });
    };

    svc.requestWorkingMemory = function() {
      return $http.post('/api/email-data/workingmemory', {
        data: svc.filteredParticipants
      });
    };

    svc.fetchScales = function() {
      return $http.post('/api/data/scales', {
        data: svc.filteredParticipants
      });
    };

    svc.requestScale = function(sId) {
      return $http.post('/api/email-data/subscale/' + sId, {
        data: svc.filteredParticipants
      });
    };

    svc.runFilter = function(filters) {
      var matches = $filter('participantFilter')(svc.participants, filters);
      changeFiltered(matches);
    };

    function changeFiltered(newData) {
      angular.copy(newData, svc.filteredParticipants);
      $rootScope.$broadcast('filtered-list-changed');
    }

    return svc;
  }

})();