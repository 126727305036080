(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('DisplayQuestionnaireController', DisplayQuestionnaireController);

  DisplayQuestionnaireController.$inject = ['$state', '$stateParams', '$sce', 'participateDataService', 'toastr'];

  function DisplayQuestionnaireController($state, $stateParams, $sce, participateDataService, toastr) {
    var vm = this;

    vm.qnr = {};

    vm.instructions = instructions;
    vm.submit = submit;

    activate();

    function activate() {
      return participateDataService.fetch($stateParams.id)
        .then(function(result) {
          vm.qnr = result.data;
          return vm.qnr;
        });
    }

    function instructions(html) {
      return $sce.trustAsHtml(html);
    }

    function submit(isValid, data) {
      if ( ! isValid) return;

      participateDataService.store($stateParams.id, data)
        .then(function() {
          toastr.success('Responses have been saved.');
          $state.go('account');
        });
    }

  }

})();