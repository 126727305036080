(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('recruitmentDataService', recruitmentDataService);

  recruitmentDataService.$inject = ['$http'];

  function recruitmentDataService($http) {

    return {
      destroy: destroy,
      getSources: getSources,
      getSource: getSource,
      store: store,
      update: update
    };

    function destroy(id) {
      return $http.delete('/api/recruitment/' + id);
    }

    function getSource(id) {
      return $http.get('/api/recruitment/' + id + '/edit');
    }

    function getSources() {
      return $http.get('/api/recruitment');
    }

    function store(data) {
      return $http.post('/api/recruitment', data);
    }

    function update(data) {
      return $http.put('/api/recruitment/' + data.id, data);
    }

  }

})();