(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .controller('GeneTypesController', GeneTypesController);

  GeneTypesController.$inject = ['$scope', '$uibModalInstance', 'geneTypesService'];

  function GeneTypesController ($scope, $uibModalInstance, geneTypesService) {
    var vm = this;

    vm.cancel = cancelEdit;
    vm.editing = false;
    vm.formData = {};
    vm.types = geneTypesService.all();

    vm.dismiss = dismissModal;
    vm.edit = editType;
    vm.remove = remove;
    vm.submit = submit;

    function cancelEdit() {
      vm.editing = false;
      vm.formData = {};
    }

    function dismissModal () {
      $uibModalInstance.close();
    }

    function editType(geneType) {
      vm.editing = true;
      vm.formData = angular.copy(geneType);
    }

    function remove(type) {
      geneTypesService.remove(type);
    }

    function submit (isValid, data) {
      if (! isValid) return;

      if (data.id) {
        geneTypesService.update(data);
      } else {
        geneTypesService.add(data);
      }
      vm.editing = false;
      vm.formData = {};

      $scope.geneTypeForm.$setPristine();
      $scope.geneTypeForm.$setUntouched();
    }

  }

})();
