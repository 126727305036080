(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('permissionsService', permissionsService);

  permissionsService.$inject = ['aiStorage'];

  function permissionsService(aiStorage) {

    return {
      hasPermission: hasPermission
    };

    function hasPermission(requiredPermission) {
      var permissions = angular.fromJson(aiStorage.get('permissions'));

      if (Array.isArray(permissions)) {
        return permissions.indexOf(requiredPermission) > -1;
      }

      return false;
    }

  }

})();