(function() {
  'use strict';

  angular
    .module('Data')
    .controller('DataTaskController', DataTaskController);

  DataTaskController.$inject = ['$scope', 'dataService', 'toastr'];

  function DataTaskController($scope, dataService, toastr) {
    var ctrl = this;

    ctrl.tasks = [];

    ctrl.requestWMData = requestWMData;

    function requestWMData() {
      dataService.requestWorkingMemory()
        .then(function() {
          toastr.success('Data requested received, you will receive an e-mail soon.');
        });
    }

    $scope.$on('filtered-list-changed', function() {
      dataService.fetchTasks()
        .then(function(response) {
          ctrl.tasks = response.data;
        });
    });
  }

})();