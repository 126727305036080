(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('DemographicsEditController', DemographicsEditController);

  DemographicsEditController.$inject = ['$scope', '$uibModal', 'toastr'];

  function DemographicsEditController($scope, $uibModal, toastr) {
    var vm = this;

    vm.edit = edit;

    function edit(participant) {

      var editModal = $uibModal.open({
        animation: true,
        templateUrl: '../views/participants/demographics-edit-modal.html',
        resolve: {
          data: function() {
            return {
              id: participant.id,
              demographic: angular.copy(participant.demographic)
            };
          }
        },
        controller: function($scope, $uibModalInstance, participantDataService, participantsDropdowns, qualificationDataService, ethnicityDataService, data) {
          $scope.data = data.demographic;
          $scope.gender = participantsDropdowns.gender();
          $scope.handedness = participantsDropdowns.handedness();
          $scope.native_english = participantsDropdowns.native();

          qualificationDataService.fetch()
            .then(function(data) {
              $scope.qualifications = data.data;
            });

          ethnicityDataService.fetch()
            .then(function(data) {
              $scope.ethnicities = data.data;
            });

          $scope.dismiss = function() {
            $uibModalInstance.dismiss();
          };

          $scope.update = function(isValid, demo) {
            if (! isValid) return;

            console.log(demo.dob);

            demo.dob = demo.dob.getFullYear() + '-' + (demo.dob.getMonth() + 1) +  '-' + demo.dob.getDate();

            console.log(demo.dob);

            participantDataService.updateDemographics(data.id, demo)
              .then(function() {
                $uibModalInstance.close();
              });
          };

        }
      });

      editModal.result
        .then(function() {
          $scope.$emit('participant-record-updated');
          toastr.success('Demographic details updated');
        });
    }

  }

})();