(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .factory('geneService', geneService);

  geneService.$inject = ['$q', 'Gene', 'toastr'];

  function geneService($q, Gene, toastr) {
    var list = [];

    return {
      all: listAllGenes,
      remove: remove,
      save: saveGene,
      update: updateGene
    };

    function listAllGenes() {
      if (list.length) return list;
      return Gene.query(function(response) {
        list = response;
        return list;
      });
    }

    function remove(gene) {
      var name = gene.name;
      gene.$remove(function() {
        var index = list.indexOf(gene);
        if (index >= 0) list.splice(index, 1);
        toastr.success(name + ' has been removed.');
      });
    }

    function saveGene(newGene) {
      var gene = new Gene(newGene);
      var dfd = $q.defer();

      gene.$save(function(response) {
        list.push(response);
        toastr.success('New Gene details recorded.');
        dfd.resolve();
      }, function() {
        dfd.reject();
      });

      return dfd.promise;
    }

    function updateGene(gene) {
      var dfd = $q.defer();

      gene.$update(function(response) {
        list.map(function(item, index) {
          if (item.id === response.id) list[index] = response;
        });
        toastr.success('Gene details updated');
        dfd.resolve();
      }, function() {
        dfd.reject();
      });

      return dfd.promise;
    }

  }

})();