(function() {
  'use strict';

  angular
    .module('Hexagon')
    .controller('HexagonUploadController', HexagonUploadController);

    HexagonUploadController.$inject = ['$state', '$stateParams', 'Upload', 'toastr'];

    function HexagonUploadController($state, $stateParams, Upload, toastr) {
      var vm = this;

      vm.fileErrors = [];
      vm.participant_id = $stateParams.id;
      vm.progress = 0;

      vm.upload = upload;

      function upload(isValid, files) {
        if (!isValid) return;

        Upload.upload({
          url: '/api/hexagon/upload',
          data: {
            participant_id: vm.participant_id,
            excelFile: files.excelFile,
            edatFile: files.edatFile
          }
        }).progress(function(evt) {
          vm.progress = parseInt(100.0 * evt.loaded / evt.total);
        }).success(function() {
          toastr.success('Hexagon Data Stored.');
          $state.go('participants.show', { id: vm.participant_id });
        }).error(function(error) {
          vm.fileErrors = error.missing;
          toastr.error(error.message, "Excel File Error");
        });
      }

    }
    
})();