(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('PermissionIndexController', PermissionIndexController);

    PermissionIndexController.$inject = ['permissionDataService', 'toastr'];

    function PermissionIndexController(permissionDataService, toastr) {
      var vm = this;

      vm.permissions = [];

      vm.populate = populate;
      vm.clean = clean;

      activate();

      function activate() {
        permissionDataService.fetch()
          .then(function(data) {
            vm.permissions = data.data;
            return vm.permissions;
          });
      }

      function clean() {
        permissionDataService.clean()
          .then(function(data) {
            activate();
            toastr.success(data.data.message);
          });
      }

      function populate() {
        permissionDataService.populate()
          .then(function(data) {
            activate();
            toastr.success(data.data.message);
          });
      }

    }
    
})();