(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {

    $stateProvider
      .state('roles', {
        abstract: true,
        url: '/roles',
        template: '<ui-view />'
      })
      .state('roles.index', {
        url: '',
        templateUrl: '../views/roles/index.html',
        controller: 'RoleIndexController',
        controllerAs: 'IndexCtrl',
        authenticate: true,
        permission: 'roles-view'
      })
      .state('roles.show', {
        url: '/:id',
        templateUrl: '../views/roles/show.html',
        controller: 'RoleShowController',
        controllerAs: 'ShowCtrl',
        authenticate: true,
        permission: 'roles-view'
      });
  }

})();