(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('datePickerController', datePickerController);

    datePickerController.$inject = [];

    function datePickerController() {
      var vm = this;

      vm.minDate = vm.minDate ? null : new Date();
      vm.status = {
        opened: false
      };

      vm.format = 'dd-MM-yyyy';

      vm.open = open;

      function open($event) {
        vm.status.opened = true;
      }

    }
    
})();