(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('userDataService', userDataService);

  userDataService.$inject = ['$http'];

  function userDataService($http) {
    var url = '/api/users';

    return {
      toggleRoles: toggleRoles,
      search: search,
      show: show
    };

    function toggleRoles(userId, roleId) {
      return $http.put('/api/users/toggle-role', {
        'userId': userId,
        'roleId': roleId
      });
    }

    function search(data) {
      return $http.post(url + '/search', data);
    }

    function show(id) {
      return $http.get(url + '/' + id);
    }

  }

})();