(function() {
  'use strict';

  angular
    .module('WorkingMemory')
    .factory('workingMemoryService', workingMemoryService);

  workingMemoryService.$inject = ['$q', '$http', 'Upload'];

  function workingMemoryService($q, $http, Upload) {

    return {
      destroy: destroy,
      download: download,
      upload: fileUpload
    };

    function destroy(id) {
      return $http.delete('/api/working-memory/' + id);
    }

    function download(){
      var dfd = $q.defer();
      var defaultFileName = 'download';

      $http.get('/api/working-memory/template', {responseType: "arraybuffer"})
        .success(function (data, status, headers) {
          var type = headers('Content-Type');
          var disposition = headers('Content-Disposition');

          if (disposition) {
            var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
            if (match[1])
              defaultFileName = match[1];
          }

          defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
          var blob = new Blob([data], { type: type });
          saveAs(blob, defaultFileName);

          dfd.resolve(defaultFileName);
        })
        .error(function (data, status) {
          dfd.reject();
        });

      return dfd.promise;
    }

    function fileUpload(file) {
      return Upload.upload({
        url: '/api/working-memory',
        data: {
          excel: file.excelFile
        }
      });
    }

  }

})();