(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('ApplicationIndexController', ApplicationIndexController);

    ApplicationIndexController.$inject = ['$scope', 'applicationDataService', 'roleDataService', 'toastr'];

    function ApplicationIndexController($scope, applicationDataService, roleDataService, toastr) {
      var vm = this;

      vm.applications = [];
      vm.approveId = 0;
      vm.formData = {};
      vm.rejectId = 0;
      vm.roles = [];

      vm.approve = approve;
      vm.approving = approving;
      vm.rejecting = rejecting;
      vm.reject = reject;

      activate();
      retrieveRoles();

      function activate() {
        applicationDataService.pending()
          .then(function(data) {
            vm.applications = data.data;
            return vm.applications;
          });
      }

      function approve(isValid, data) {
        if ( ! isValid) return;

        applicationDataService.approve(vm.approveId, data)
          .then(function() {
            activate();
            $scope.$broadcast('application-approved');
            toastr.success('Application Approved.');
          });
      }

      function approving(id, date) { 
        vm.rejectId = 0;
        vm.approveId = (vm.approveId === id) ? 0 : id;
        vm.formData = {
          access_deadline: date
        };
      }

      function reject(isValid, data) {
        if ( ! isValid) return;

        applicationDataService.reject(vm.rejectId, data)
          .then(function() {
            activate();
            $scope.$broadcast('application-rejected');
            toastr.info('Application Rejected');
          });
      }

      function rejecting(id) {
        vm.approveId = 0;
        vm.rejectId = (vm.rejectId === id) ? 0 : id;
      }

      function retrieveRoles() {
        roleDataService.fetch()
          .then(function(data) {
            vm.roles = data.data;
            return vm.roles;
          });
      }

    }
    
})();