(function() {
  'use strict';
  
  angular
    .module('GeneManagement')
    .controller('GeneUploadController', GeneUploadController);
    
  GeneUploadController.$inject = ['Upload'];
  
  function GeneUploadController(Upload) {
    var vm = this;

    vm.errFile = [];
    vm.errors = {};
    vm.file = {};
    vm.results = [];

    vm.closeAlert = closeAlert;
    vm.uploadFile = uploadFile;

    function closeAlert(index) {
      if (vm.errors.row.length) vm.errors.row.splice(index, 1);
      if (vm.results.length) vm.results.splice(index, 1);
    }

    function uploadFile(file, errFiles) {
      vm.file = file;
      vm.errFile = errFiles && errFiles[0];


      if (file) {
        vm.errors = {};
        vm.results = [];

        file.upload = Upload.upload({
          url: '/api/genes/upload',
          data: {import: file}
        });

        file.upload
          .then(function(res) {
            vm.results = res.data;
            return vm.results;

          }, function(err) {
            vm.errors = err.data;
            return vm.errors;

          }, function(evt) {
            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
          });

      }
    }
    
  }  

})();