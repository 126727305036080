(function() {
  'use strict';

  // intercepts state changes and prevents user from seeing
  // a route before the server side auth kicks in

  angular
    .module('Genetics')
    .run(run);

  run.$inject = ['$rootScope', '$state', 'authenticateService'];

  function run($rootScope, $state, authenticateService) {

    $rootScope.$on('$stateChangeStart', function(event, toState, toParams) {

      if (toState.authenticate && !authenticateService.isAuthenticated()) {
        event.preventDefault();

        // store original route details on $rootScope so we can return user
        // to original destination after login
        $rootScope.returnToState = toState.name;
        $rootScope.returnToStateParams = toParams;

        $state.go('signin');
      }

      // if user authenticated don't allow to visit signin page
      if (toState.name == 'signin' && authenticateService.isAuthenticated()) {
        event.preventDefault();
        $state.go('account');
      }

      // if user authenticated don't allow to visut forgot password page
      if (toState.name == 'forgot-password' && authenticateService.isAuthenticated()) {
        event.preventDefault();
        $state.go('account');
      }

    });

  }

})();