(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$httpProvider'];

  function config($httpProvider) {
    $httpProvider.interceptors.push('broadcastFormErrors');

    $httpProvider.interceptors.push('catchDuplicateRequest');

    $httpProvider.interceptors.push('pageNotFound');

    $httpProvider.interceptors.push('unauthorised');

    $httpProvider.interceptors.push('notAuthenticated');

    $httpProvider.interceptors.push('confirmDeleteRequest');
  }

})();