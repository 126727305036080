(function() {
  'use strict';

  angular
    .module('WorkingMemory')
    .config(workingMemoryRoutes);

  workingMemoryRoutes.$inject = ['$stateProvider'];

  function workingMemoryRoutes($stateProvider) {

    $stateProvider
      .state('working-memory', {
        abstract: true,
        url: '/working-memory',
        template: '<ui-view />'
      })
      .state('working-memory.index', {
        url: '',
        authenticate: true,
        permission: 'working-memory-view'
      })
      .state('working-memory.upload', {
        url: '/upload',
        controller: 'WorkingMemoryUploadController',
        controllerAs: 'UploadCtrl',
        templateUrl: '../views/working-memory/upload.html',
        authenticate: true,
        permission: 'working-memory-upload'
      });

  }

})();