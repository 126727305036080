(function () {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider', '$urlRouterProvider'];

  function config($stateProvider, $urlRouterProvider) {

    $stateProvider
      .state('ethnicities', {
        abstract: true,
        url: '/ethnicities',
        template: '<ui-view />'
      })
      .state('ethnicities.index', {
        url: '',
        templateUrl: '../views/ethnicities/index.html',
        controller: 'EthnicityIndexController',
        controllerAs: 'ethnicitiesCtrl',
        authenticate: true,
        permission: 'ethnicities-view'
      });
  }

})();