(function() {
  angular
    .module('Genetics')
    .controller('QuestionnaireIndexController', QuestionnaireIndexController);

  QuestionnaireIndexController.$inject = ['$scope', 'questionnaireDataService', 'toastr'];


  function QuestionnaireIndexController($scope, questionnaireDataService, toastr) {
    var vm = this;

    vm.questionnaires = [];

    vm.publish = publish;
    vm.download = download;

    activate();

    function activate() {
      return questionnaireDataService.fetch()
        .then(function(data) {
          vm.questionnaires = data.data;
          return vm.questionnaires;
        });
    }

    function download(id) {
      return questionnaireDataService.download(id)
        .catch(function() {
          toastr.error('Failed to generate excel file.');
        });
    }

    function publish(id) {
      return questionnaireDataService.publish(id)
        .then(function(data) {
          toastr.success(data.data.message);
          activate();
        });
    }

    $scope.$on('questionnaire-updated', function() {
      activate();
    });

  }

})();