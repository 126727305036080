(function() {
  'use strict';

  angular
    .module('Genetics')
    .filter('capitalize', capitalize);

  function capitalize() {
    return function(value) {
      if (value != null) {
        value = value.toString();
        value.toLowerCase();
        return value.substring(0,1).toUpperCase() + value.substring(1);
      }
    }
  }

})();