(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .factory('Allele', AlleleModel);

  function AlleleModel() {
    function Allele(id, gene_id, value, allele, count) {
      this.id = id;
      this.gene_id = gene_id;
      this.value = value;
      this.allele = allele;
      this.count = count;
    }

    Allele.fromJson = function(data) {
      if (angular.isArray(data)) {
        return data.map(Allele.fromJson);
      }
      return new Allele(data.id, data.gene_id, data.value, data.allele, data.allele_count);
    }

    return Allele;
  }

})();