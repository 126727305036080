(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('UserIndexController', UserIndexController);

    UserIndexController.$inject = ['userDataService', 'toastr'];

    function UserIndexController(userDataService, toastr) {
      var vm = this;

      vm.loading = false;
      vm.searched = false;
      vm.users = [];

      vm.search = search;

      function search(isValid, data) {
        if ( ! isValid) return;

        vm.loading = true;
        vm.searched = true;

        userDataService.search(data)
          .then(function(data) {
            vm.users = data.data;
            vm.loading = false;
            return vm.users;
          });
      }
      
    }
    
})();