(function() {
  'use strict';

  angular
    .module('Hexagon')
    .factory('hexagonDataService', hexagonDataService);

  hexagonDataService.$inject = ['$q', '$http'];

  function hexagonDataService($q, $http) {
    return {
      destroy: destroy,
      download: download
    };

    function destroy(hexagonId) {
      return $http.delete('/api/hexagon/' + hexagonId);
    }

    function download(hexagonId) {
      var dfd = $q.defer();
      var defaultFileName = 'download';

      $http.get('/api/hexagon/download/' + hexagonId, {responseType: "arraybuffer"})
        .success(function (data, status, headers) {
          var type = headers('Content-Type');
          var disposition = headers('Content-Disposition');

          if (disposition) {
            var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
            if (match[1]) defaultFileName = match[1];
          }
          console.log(disposition);
console.log(type);
          defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
          var blob = new Blob([data], { type: type });
          saveAs(blob, defaultFileName);

          dfd.resolve(defaultFileName);
        })
        .error(function (data, status) {
          dfd.reject();
        });

      return dfd.promise;
    }

  }

})();