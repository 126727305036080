(function() {
  'use strict';

  angular
    .module('WorkingMemory')
    .controller('WorkingMemoryUploadController', WorkingMemoryUploadController);

  WorkingMemoryUploadController.$inject = ['workingMemoryService', 'toastr'];

  function WorkingMemoryUploadController(workingMemoryService, toastr) {
    var ctrl = this;

    ctrl.missingColumns = [];
    ctrl.rowErrors = [];

    ctrl.downloadTemplate = download;
    ctrl.uploadFile = uploadFile;


    function download() {
      return workingMemoryService.download()
        .catch(function() {
          toastr.error('Failed to download template');
        });
    }

    function uploadFile(isValid, file) {
      if (! isValid) return;

      ctrl.missingColumns = [];
      ctrl.rowErrors = [];

      workingMemoryService.upload(file)
        .then(function(response) {
          toastr.success(response.data.message);
        }, function(error) {
          ctrl.rowErrors = error.data.rowErrors;
          ctrl.missingColumns = error.data.missingColumns;
          toastr.error('There are problems with the file, please check the errors.', 'File Error')
        });

    }
    
  }
  
})();