(function() {
  'use strict';

  angular
    .module('SubScale')
    .config(routes);

  function routes($stateProvider) {

    $stateProvider
      .state('sub-scales', {
        abstract: true,
        url: '/sub-scales',
        template: '<ui-view />'
      })
      .state('sub-scales.index', {
        url: '',
        controller: 'SubScaleIndexController',
        controllerAs: 'ScaleCtrl',
        templateUrl: '../views/sub-scales/index.html',
        authenticate: true,
        permission: 'subscale-view'
      })
      .state('sub-scales.create', {
        url: '/create',
        controller: 'SubScaleCreateController',
        controllerAs: 'CreateCtrl',
        templateUrl: '../views/sub-scales/create.html',
        authenticate: true,
        permission: 'subscale-create'
      })
      .state('sub-scales.edit', {
        url: '/edit/:id',
        controller: 'SubScaleEditController',
        controllerAs: 'EditCtrl',
        templateUrl: '../views/sub-scales/edit.html',
        authenticate: true,
        permission: 'subscale-edit'
      });

  }

})();