(function() {
  'use strict';

  angular
    .module('Genetics')
    .directive('buttonGroup', buttonGroup);

  buttonGroup.$inject = [];

  function buttonGroup() {

    return {
      require: '^form',
      scope: {
        question: '=',
        model: '=',
        required: '='
      },
      restrict: 'E',
      templateUrl: '../views/_partials/button-group.html',
      link: function(scope, element, attrs, formCtrl) {
        scope.thisForm = formCtrl;
      }
    };

  }

})();