(function () {
  'use strict';

  angular
    .module('Genetics')
    .factory('ethnicityDataService', ethnicityDataService);

  ethnicityDataService.$inject = ['$http'];

  function ethnicityDataService($http) {
    return {
      destroy: destroy,
      fetch: fetch,
      store: store,
      update: update
    };

    function destroy(ethnicity_id) {
      return $http.delete('/api/ethnicities/' + ethnicity_id);
    }

    function fetch() {
      return $http.get('/api/ethnicities');
    }

    function store(data) {
      return $http.post('/api/ethnicities', data);
    }

    function update(data) {
      return $http.put('/api/ethnicities/' + data.id, data);
    }
  }

})();