(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('applicationDataService', applicationDataService);

  applicationDataService.$inject = ['$http', '$filter'];

  function applicationDataService($http, $filter) {
    var url = '/api/applications';

    return {
      approve: approve,
      approved: approved,
      pending: pending,
      reject: reject,
      rejected: rejected,
      renew: renew,
      retrieve: retrieve,
      save: save
    };

    function approve(id, data) {
      return $http.put(url + '/approve/' + id, data);
    }

    function approved() {
      return $http.get(url + '/approved');
    }

    function pending() {
      return $http.get(url + '/pending');
    }

    function reject(id, data) {
      return $http.put(url + '/reject/' + id, data);
    }

    function rejected() {
      return $http.get(url + '/rejected');
    }

    function renew(data) {
      return $http.post(url + '/renew', data);
    }

    function retrieve(id) {
      return $http.get(url + '/status/' + id);
    }

    function save(data) {
      return $http.post(url, data);
    }

  }

})();