/**
 * $http Interceptor service that asks for confirmation of delete actions
 */

(function() {
  'use strict';

  angular
    .module('Genetics')
    .factory('confirmDeleteRequest', confirmDeleteRequest);

  confirmDeleteRequest.$inject = ['$q', '$injector'];

  function confirmDeleteRequest($q, $injector) {
    return {
      request: request
    };

    function request(requestObj) {

      if (requestObj.method === 'DELETE') {
        var $uibModal = $injector.get('$uibModal');
        var dfd = $q.defer();

        $uibModal.open({
          templateUrl: '../views/_partials/confirm-delete-modal.html',
          size: 'sm',
          controller: function($scope, $uibModalInstance) {
            $scope.confirmed = function() {
              $uibModalInstance.dismiss();
              dfd.resolve(requestObj);
            };

            $scope.cancel = function() {
              $uibModalInstance.dismiss();
              dfd.reject(generateFakeResponse(requestObj));
            };
          }
        });

        return dfd.promise;
      }

      return requestObj;
    }

    // fake response promise, as must return a promise obj
    function generateFakeResponse(request) {

      var response = {
        data: {},
        headers: {},
        status: 499,
        config: request
      };

      return response;
    }

  }

})();