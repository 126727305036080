(function () {
  'use strict';

  angular
    .module('Genetics')
    .directive('preventDblClk', preventDblClk);

  preventDblClk.$inject = ['$timeout'];

  function preventDblClk($timeout) {
    var delay = 500;

    return {
      restrict: 'A',
      priority: -1,
      link: function(scope, elem) {
        var disabled = false;

        function onClick(evt) {
          if (disabled) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
          } else {
            disabled = true;
            $timeout(function() {
              disabled = false;
            }, delay, false);
          }
        }

        scope.$on('$destroy', function() {
          elem.off('click', onClick);
        });

        elem.on('click', onClick);
      }
    }

  }

})();