(function() {
  'use strict';

  angular
    .module('Genetics',
    [
      'ui.router',
      'ngAnimate',
      'ngSanitize',
      'ngMessages',
      'toastr',
      'ui.bootstrap',
      'angular-jwt',
      'angular-storage',
      'ngFileUpload',
      'GeneManagement',
      'Hexagon',
      'WorkingMemory',
      'SubScale',
      'Data'
    ]);

})();