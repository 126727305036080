(function() {
  'use strict';

  angular
    .module('Genetics')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {

    $stateProvider
      .state('permissions', {
        abstract: true,
        url: '/permissions',
        template: '<ui-view />'
      })
      .state('permissions.index', {
        url: '',
        templateUrl: '../views/permissions/index.html',
        controller: 'PermissionIndexController',
        controllerAs: 'IndexCtrl',
        authenticate: true,
        permission: 'permissions-view'
      });
      
  }

})();