(function() {
  'use strict';

  angular
    .module('Genetics')
    .controller('MedicalEditController', MedicalEditController);

  MedicalEditController.$inject = ['$scope', '$uibModal', 'toastr'];

  function MedicalEditController($scope, $uibModal, toastr) {
    var vm = this;

    vm.edit = edit;

    function edit(participant) {

      var editModal = $uibModal.open({
        animation: true,
        templateUrl: '../views/participants/edit-medical-modal.html',
        resolve: {
          data: function() {
            return {
              id: participant.id,
              medical: angular.copy(participant.medical)
            }
          }
        },
        controller: function($scope, $uibModalInstance,participantsDropdowns, participantDataService, data) {

          $scope.data = data.medical;
          $scope.smoker = participantsDropdowns.smoker();
          $scope.drinker = participantsDropdowns.drinker();

          $scope.dismiss = function() {
            $uibModalInstance.dismiss('cancel');
          };

          $scope.update = function(isValid, medical) {
            if (! isValid) return;

            if (medical.drinks != 1) medical.drinks_unit = null;
            if (medical.smokes != 1) medical.smokes_quantity = null;

            participantDataService.updateMedical(data.id, medical)
              .then(function() {
                $uibModalInstance.close();
              });
          };

        }
      });

      editModal.result
        .then(function() {
          $scope.$emit('participant-record-updated');
          toastr.success('Participant medical details updated.');
        });

    }

  }

})();