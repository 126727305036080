(function() {
  'use strict';

  angular
    .module('GeneManagement')
    .controller('GeneListController', GeneListController);

  GeneListController.$inject = ['$uibModal', 'geneService', 'geneTypesService'];

  function GeneListController($uibModal, geneService, geneTypesService) {
    var vm = this;

    vm.genes = geneService.all();
    vm.types = geneTypesService.all();

    vm.openGeneModal = geneFormModal;
    vm.openTypesModal = geneTypesModal;
    vm.remove = remove;


    function geneFormModal (editGene) {
      $uibModal.open({
        templateUrl: '../views/genes/gene-form-modal.html',
        controller: 'GeneFormController as GFCtrl',
        resolve: {
          editGene: function() {
            return angular.copy(editGene);
          }
        }
      });
    }

    function geneTypesModal () {
      $uibModal.open({
        templateUrl: '../views/genes/gene-types-modal.html',
        controller: 'GeneTypesController as GTCtrl'
      });
    }

    function remove(gene) {
      geneService.remove(gene);
    }

  }

})();